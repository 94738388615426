import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSearch = _resolveComponent("InputSearch")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    gutter: [36, 18],
    class: "performer-search-form"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, {
        span: 24,
        lg: 12
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSearch, {
            label: _ctx.t('Search'),
            value: _ctx.internalFormState.query,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalFormState.query) = $event)),
            placeholder: _ctx.t('Type your query here'),
            "on-search": _ctx.onSearch,
            "on-enter-button": _ctx.onEnterButton,
            loading: _ctx.searchOnKeystroke ? false : _ctx.loading,
            "enter-button": ""
          }, null, 8, ["label", "value", "placeholder", "on-search", "on-enter-button", "loading"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 24,
        lg: 4
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputDropdown, {
            label: _ctx.t('Gender'),
            options: _ctx.genderOptions,
            value: _ctx.filterModel.gender,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterModel.gender) = $event)),
            placeholder: _ctx.t('All'),
            class: "ant-form-vertical"
          }, null, 8, ["label", "options", "value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 24,
        lg: 4
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputDropdown, {
            label: _ctx.t('Age'),
            options: _ctx.ageOptions,
            value: _ctx.internalFormState.age,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.internalFormState.age) = $event)),
            placeholder: _ctx.t('All'),
            class: "ant-form-vertical"
          }, null, 8, ["label", "options", "value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 24,
        lg: 4
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputDropdown, {
            label: _ctx.t('Industry'),
            options: _ctx.industryOptions,
            placeholder: _ctx.t('All'),
            class: "ant-form-vertical",
            value: _ctx.filterModel.industryId,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterModel.industryId) = $event)),
            "show-icons": ""
          }, null, 8, ["label", "options", "placeholder", "value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}