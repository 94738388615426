import { RecommendedPerformersInput } from "@/../__generated__/globalTypes";
import { recommendedPerformersGql } from "@/api/performer/recommendedPerformers";
import {
  RecommendedPerformers,
  RecommendedPerformersVariables,
  RecommendedPerformers_recommendedPerformers_RecommendedPerformersResults,
} from "@/api/performer/__generated__/RecommendedPerformers";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { computed, reactive, ref } from "vue";

const DEFAULT_INPUT: RecommendedPerformersInput = {};

/**
 * Composable to fetch recommended performers
 * @param initialInput Initial input variable values
 * @param enabled Set to false to prevent error when orderId is not yet supplied (default=`true`)
 */
export const useRecommendedPerformers = (
  initialInput?: Partial<RecommendedPerformersInput>,
  enabled = true
) => {
  const recommendedPerformersFilter: RecommendedPerformersInput = reactive({
    ...DEFAULT_INPUT,
    ...initialInput,
  });

  const queryEnabled = ref(enabled);

  const { result, loading: recommendedPerformersLoading } = useQuery<
    RecommendedPerformers,
    RecommendedPerformersVariables
  >(
    recommendedPerformersGql,
    () => ({
      input: recommendedPerformersFilter,
    }),
    () => ({
      enabled: queryEnabled.value,
    })
  );

  const recommendedPerformers = computed(() => {
    const parsedResponse =
      parseGqlResponse<RecommendedPerformers_recommendedPerformers_RecommendedPerformersResults>(
        "RecommendedPerformersResults",
        result.value
      ).data;

    return parsedResponse?.results;
  });

  return {
    queryEnabled,
    recommendedPerformersFilter,
    recommendedPerformers,
    recommendedPerformersLoading,
  };
};
