
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import ModalPrompt from "@/shared/components/ModalPrompt.vue";
import PerformerGrid from "@/shared/components/Performers/PerformerGrid.vue";
import Avatar from "@/shared/components/Avatar.vue";
import { useOrder } from "@/shared/composables/useOrder";
import { usePerformers } from "@/shared/composables/usePerformers";
import { useQuotation } from "@/shared/composables/useQuotation";
import { useRecommendedPerformers } from "@/shared/composables/useRecommendedPerformers";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { computed, defineComponent, watchEffect, ref } from "vue";
import { useI18n } from "vue-i18n";
import isEmpty from "lodash/isEmpty";
import PerformerSearchForm from "@/shared/components/Performers/PerformerSearchForm.vue";
import { useRoute } from "vue-router";
import routeNames from "@/web/router/routeNames";
import message from "@/shared/utils/message";
import { galleryPlaceholderSrc } from "@/shared/utils/constants";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";

export default defineComponent({
  // For keep-alive
  name: routeNames.performerSelect,
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { redirectToPerformerDetails, redirectToOrderDetails } =
      useRedirectHelper();
    const { isXs } = useWindowSizeChecker();

    const orderId = ref(String(route.params.orderId));

    // Performer Delete Modal
    const performerDeleteModalRef = ref<InstanceType<typeof ModalPrompt>>();
    const selectedPerformerDeleteId = ref<string>();

    // Recommended Performers
    const {
      queryEnabled,
      recommendedPerformersFilter,
      recommendedPerformers,
      recommendedPerformersLoading,
    } = useRecommendedPerformers({}, false);

    const {
      order,
      orderLoading,
      selectedPerformers,
      removePerformer,
      getRemainingPerformerSlots,
      confirmPerformers,
      confirmPerformersInOrderLoading,
    } = useOrder({ orderId });

    const { maxPerformerCount, quotationLoading } = useQuotation({
      quotationId: computed(() => order.value?.quotation?.id ?? ""),
    });

    const { businessProfile } = useBusinessProfile();

    watchEffect(() => {
      if (order.value) {
        recommendedPerformersFilter.orderId = Number(order.value.id);
        queryEnabled.value = true; // re-enable query
      }
    });

    watchEffect(() => {
      if (businessProfile.value && businessProfile.value.industry) {
        performersFilter.industryId = Number(businessProfile.value.industry.id);
      }
    });

    // Performer Search
    const {
      performersFilter,
      performers,
      performersLoading,
      performersFetchMore,
      performersFetchMoreLoading,
      performersHasNextPage,
    } = usePerformers();

    const handleSearch = (query: string) => {
      performersFilter.query = query;
    };

    // === Handlers ===
    const handlePerformerClick = (id: string) => {
      console.log("handlePerformerSelect: ", id);
      redirectToPerformerDetails({ id: id, orderId: orderId.value });
    };

    const handlePerformerDelete = (id: string) => {
      selectedPerformerDeleteId.value = id;

      performerDeleteModalRef.value?.openModal();
    };

    const handlePerformerConfirmDelete = async () => {
      const response = await removePerformer({
        orderId: order.value?.id,
        userId: selectedPerformerDeleteId.value,
      });

      if (response?.success) {
        message["success"](
          response.message ??
            t(
              "Successfully canceled request for working with this model creator."
            )
        );
      }

      performerDeleteModalRef.value?.closeModal();
    };

    const handlePerformerSubmitButton = async () => {
      const response = await confirmPerformers({
        orderId: order.value?.id,
      });

      if (response?.success) {
        redirectToOrderDetails({ orderId: order.value?.id ?? "" });
      }
    };

    return {
      t,
      maxPerformerCount,
      orderLoading: computed(
        () => orderLoading.value || quotationLoading.value
      ),
      selectedPerformers,
      selectedPerformerIds: computed(() =>
        selectedPerformers.value.map((performer) => performer.id)
      ),
      remainingSlots: computed(() =>
        getRemainingPerformerSlots(maxPerformerCount.value)
      ),
      isEmpty,
      handleSearch,
      performersFilter,
      performers,
      performersLoading,
      performersFetchMore,
      performersFetchMoreLoading,
      performersHasNextPage,
      recommendedPerformersFilter,
      recommendedPerformers,
      recommendedPerformersLoading,
      handlePerformerClick,
      handlePerformerDelete,
      handlePerformerConfirmDelete,
      performerDeleteModalRef,
      galleryPlaceholderSrc,
      isXs,
      handlePerformerSubmitButton,
      performerSubmitButtonLoading: confirmPerformersInOrderLoading,
    };
  },
  components: {
    PageLayout,
    PerformerGrid,
    PerformerSearchForm,
    Avatar,
    ModalPrompt,
  },
});
