import gql from "graphql-tag";

export const performersGql = gql`
  query Performers($input: PerformersInput!) {
    performers(input: $input) {
      ... on PerformerResults {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
        }
        results {
          id
          name
          performerProfile {
            nickname
            introduction
            age
            industries {
              id
              name
            }
            gender
            isLocked
            avatar
            videoUrls
            location
            deliveryTime
            rating
            videoCount
            images {
              image
              description
              altText
              slug
              thumbnail
            }
            feedback
            badges {
              id
              name
            }
            isPremium
          }
        }
        totalCount
      }

      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
