
import { defineComponent, PropType, computed, reactive, watch } from "vue";
import { PerformersInput } from "@/../__generated__/globalTypes";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import { SEARCH_DEBOUNCE_INTERVAL } from "@/shared/utils/constants";
import debounce from "lodash/debounce";
import InputSearch from "@/shared/components/Forms/InputSearch.vue";
import InputDropdown, {
  DropdownOption,
  DropdownOptionObject,
} from "@/shared/components/Forms/InputDropdown.vue";
import {
  genderOptions,
  ageDropdownOptions,
  getAgeRangeValue,
} from "@/shared/utils/kycOptions";
import { useFilterOptions } from "@/shared/composables/useFilterOptions";
import { UsersBusinessProfileTargetGenderChoices } from "@/../__generated__/globalTypes";

export default defineComponent({
  props: {
    filters: {
      type: Object as PropType<PerformersInput>,
      required: true,
    },
    searchOnKeystroke: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-search", "on-enter-button"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const filterModel = useVModel(props, "filters");
    /** Use for fields that requires further logic/parsing,
     * and should not directly mutate Input variables */
    const internalFormState = reactive({
      query: "",
      age: undefined, // default to undefined so nothing is pre-selected
    });

    // Custom parsing on age range values
    watch(internalFormState, () => {
      const range = getAgeRangeValue(internalFormState.age);

      filterModel.value.minAge = range[0];
      filterModel.value.maxAge = range[1];
    });

    const { industryDropdownOptions } = useFilterOptions();

    const onSearch = props.searchOnKeystroke
      ? debounce(() => {
          emit("on-search", internalFormState.query);
        }, SEARCH_DEBOUNCE_INTERVAL)
      : null;

    const onEnterButton = () => {
      emit("on-search", internalFormState.query);
    };

    return {
      t,
      internalFormState,
      filterModel,
      onSearch,
      onEnterButton,
      // Options mapping
      genderOptions: computed<DropdownOption[]>(() => [
        { label: t("All"), value: null },
        ...genderOptions
          // Remove Does Not Matter option
          .filter(
            (gender) =>
              gender.value !==
              UsersBusinessProfileTargetGenderChoices.DOES_NOT_MATTER
          )
          // Map options to dropdown options
          .map((gender) => ({
            label: gender.label,
            value: gender.value,
          })),
      ]),
      ageOptions: ageDropdownOptions,
      industryOptions: computed(() =>
        industryDropdownOptions.value.map((option: DropdownOption<string>) => {
          const _option = option as DropdownOptionObject;

          return {
            ..._option,
            // Override industryId to number
            value: _option.value ? Number(_option.value) : null,
          };
        })
      ),
    };
  },
  components: {
    InputSearch,
    InputDropdown,
  },
});
