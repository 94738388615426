import { performersGql } from "@/api/performer/performers";
import {
  Performers,
  PerformersVariables,
  Performers_performers,
  Performers_performers_PerformerResults,
  Performers_performers_PerformerResults_results,
} from "@/api/performer/__generated__/Performers";
import { PerformersInput } from "@/../__generated__/globalTypes";
import { useQuery } from "@vue/apollo-composable";
import { computed, reactive, ref } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import { DEFAULT_PAGE_SIZE } from "@/shared/utils/constants";

const defaultFilter: PerformersInput = {
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    after: null,
  },
};

export const usePerformers = (
  pageSize = DEFAULT_PAGE_SIZE,
  initialFilter?: Partial<PerformersInput>
) => {
  const performersFilter = reactive<PerformersInput>({
    ...defaultFilter,
    ...initialFilter,
    pagination: { ...defaultFilter.pagination, pageSize: pageSize },
  });

  const {
    result: performersResult,
    loading: performersLoading,
    fetchMore,
  } = useQuery<Performers, PerformersVariables>(performersGql, () => ({
    input: performersFilter,
  }));

  const performersParsedResponse = computed(() => {
    return parseGqlResponse<Performers_performers_PerformerResults>(
      "PerformerResults",
      performersResult.value
    ).data;
  });

  const performersPageInfo = computed(() => {
    return performersParsedResponse.value?.pageInfo;
  });

  const performers = computed<Performers_performers_PerformerResults_results[]>(
    () => {
      return (
        orderBy(
          performersParsedResponse.value?.results ?? [],
          ["sortWeight"],
          ["desc"]
        ) ?? []
      );
    }
  );

  //   ===== Fetch More =====
  const performersFetchMoreLoading = ref(false);

  const performersFetchMore = async () => {
    if (
      performersPageInfo.value?.hasNextPage &&
      performersPageInfo.value.endCursor
    ) {
      performersFetchMoreLoading.value = true;

      await fetchMore({
        variables: {
          input: {
            ...performersFilter,
            pagination: {
              pageSize: pageSize,
              after: performersPageInfo.value.endCursor,
            },
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const updatedPerformersResults = {
            ...previousResult.performers,
            ...fetchMoreResult?.performers,

            // Concatenate results array
            results: [
              ...get(previousResult.performers, "results", []),
              ...get(fetchMoreResult?.performers, "results", []),
            ],
          };

          return {
            performers: <Performers_performers>updatedPerformersResults,
          };
        },
      });
    }

    performersFetchMoreLoading.value = false;
  };

  return {
    performersFilter,
    performers,
    performersLoading,
    performersFetchMore,
    performersFetchMoreLoading,
    performersHasNextPage: computed(
      () => performersPageInfo.value?.hasNextPage
    ),
  };
};
