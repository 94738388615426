import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "performer-select-page__content" }
const _hoisted_2 = { class: "performer-select-page__instructions mt-4 mb-0" }
const _hoisted_3 = { class: "performer-select-page__selected-performers-card" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "performer-select-page__selected-count flex items-center gap-6" }
const _hoisted_6 = { class: "performer-select-page__selected-performers-avatars" }
const _hoisted_7 = {
  key: 0,
  class: "performer-select-page__suggested mt-12"
}
const _hoisted_8 = { class: "performer-select-page__search mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PerformerGrid = _resolveComponent("PerformerGrid")!
  const _component_PerformerSearchForm = _resolveComponent("PerformerSearchForm")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_ModalPrompt = _resolveComponent("ModalPrompt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageLayout, { class: "performer-select-page" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_a_row, { gutter: [36, 18] }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 24,
                xl: 10
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_typography_title, {
                    level: 2,
                    class: "mb-0"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Request Model Creators")), 1)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t(
                  "Please request at most {maxPerformerCount} you wish to work with.",
                  { maxPerformerCount: _ctx.t("model creator", _ctx.maxPerformerCount) }
                )), 1)
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", null, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.t("THIS IS NOT YET FINAL.")), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.t("Note that this is subject to their availability.")) + " " + _toDisplayString(_ctx.t("Hence, the assigned Model Creator might change.")), 1)
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 24,
                xl: 14
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_a_row, {
                      class: "h-full",
                      gutter: 8
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, {
                          span: 24,
                          xl: 18
                        }, {
                          default: _withCtx(() => [
                            (_ctx.orderLoading)
                              ? (_openBlock(), _createBlock(_component_a_skeleton, {
                                  key: 0,
                                  active: "",
                                  class: "p-8"
                                }))
                              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_a_typography_title, {
                                      level: 5,
                                      class: "m-0"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("{selectedCount} / {maxCount}", {
                          selectedCount: _ctx.selectedPerformers.length,
                          maxCount: _ctx.maxPerformerCount,
                        })), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_typography_title, {
                                      level: 5,
                                      class: "m-0"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("Preferred Model Creators")), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _createElementVNode("div", _hoisted_6, [
                                    _createVNode(_component_a_row, { gutter: [16, 16] }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedPerformers, (performer) => {
                                          return (_openBlock(), _createBlock(_component_a_col, {
                                            key: performer.id,
                                            span: 6
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_Avatar, {
                                                "avatar-src": performer.performerProfile?.avatar,
                                                "alt-text": performer.performerProfile?.nickname,
                                                "show-delete-button": "",
                                                onOnDelete: ($event: any) => (_ctx.handlePerformerDelete(performer.id))
                                              }, _createSlots({ _: 2 }, [
                                                (performer.performerProfile?.nickname)
                                                  ? {
                                                      name: "extra",
                                                      fn: _withCtx(() => [
                                                        _createVNode(_component_a_typography_text, {
                                                          class: "block text-center mt-2",
                                                          strong: ""
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(performer.performerProfile?.nickname), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ])
                                                    }
                                                  : undefined
                                              ]), 1032, ["avatar-src", "alt-text", "onOnDelete"])
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128)),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.remainingSlots, (slot) => {
                                          return (_openBlock(), _createBlock(_component_a_col, {
                                            key: slot,
                                            span: 6
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_Avatar, { theme: "white-gray" })
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })
                                  ])
                                ]))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_col, {
                          span: 24,
                          xl: 6
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              class: "performer-select-page__submit-btn",
                              loading: _ctx.performerSubmitButtonLoading,
                              onClick: _ctx.handlePerformerSubmitButton
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Submit")), 1)
                              ]),
                              _: 1
                            }, 8, ["loading", "onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.recommendedPerformersLoading || !_ctx.isEmpty(_ctx.recommendedPerformers))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_a_typography_title, { level: 5 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("We suggest these for your brand")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_PerformerGrid, {
                  "performers-list": _ctx.recommendedPerformers ?? [],
                  "selected-performer-ids": _ctx.selectedPerformerIds,
                  loading: _ctx.recommendedPerformersLoading,
                  onOnItemClick: _ctx.handlePerformerClick,
                  onOnItemDelete: _ctx.handlePerformerDelete
                }, null, 8, ["performers-list", "selected-performer-ids", "loading", "onOnItemClick", "onOnItemDelete"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_a_typography_title, { level: 5 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isXs ? _ctx.t("Search Model Creator") : _ctx.t("More from our Community")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_PerformerSearchForm, {
              filters: _ctx.performersFilter,
              loading: _ctx.performersLoading || _ctx.performersFetchMoreLoading,
              onOnEnterButton: _ctx.handleSearch,
              onOnSearch: _ctx.handleSearch,
              "search-on-keystroke": "",
              class: "mb-10"
            }, null, 8, ["filters", "loading", "onOnEnterButton", "onOnSearch"]),
            _createVNode(_component_PerformerGrid, {
              "performers-list": _ctx.performers ?? [],
              "selected-performer-ids": _ctx.selectedPerformerIds,
              loading: _ctx.performersLoading,
              "has-next-page": _ctx.performersHasNextPage,
              "load-more-loading": _ctx.performersFetchMoreLoading,
              onOnLoadMore: _ctx.performersFetchMore,
              onOnItemClick: _ctx.handlePerformerClick,
              onOnItemDelete: _ctx.handlePerformerDelete
            }, null, 8, ["performers-list", "selected-performer-ids", "loading", "has-next-page", "load-more-loading", "onOnLoadMore", "onOnItemClick", "onOnItemDelete"])
          ])
        ])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ModalPrompt, {
        actions: [
        {
          title: _ctx.t('No'),
        },
        {
          title: _ctx.t('Yes'),
          action: () => _ctx.handlePerformerConfirmDelete(),
          type: 'primary',
        },
      ],
        ref: "performerDeleteModalRef",
        class: "performer-select-page__confirm-delete-modal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_paragraph, { strong: "" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Do you want to remove this model creator?")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["actions"])
    ]))
  ], 64))
}