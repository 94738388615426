import gql from "graphql-tag";

export const recommendedPerformersGql = gql`
  query RecommendedPerformers($input: RecommendedPerformersInput!) {
    recommendedPerformers(input: $input) {
      ... on RecommendedPerformersResults {
        results {
          id
          email
          created
          modified
          name
          performerProfile {
            metadata
            nickname
            introduction
            age
            industries {
              id
              created
              modified
              name
            }
            gender
            isLocked
            avatar
            videoUrls
            location
            deliveryTime
            rating
            videoCount
            images {
              created
              modified
              image
              description
              altText
              slug
            }
            badges {
              id
              name
            }
            isPremium
          }
        }
      }

      ... on ResponseErrors {
        errors {
          code
          displayMessage
          message
        }
      }
    }
  }
`;
